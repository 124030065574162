@font-face {
  font-family: 'Fedra';
  src:
    url('./fonts/FedraSansAltStd_complete_OTF/FedraSansAltStd-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
}

@font-face {
  font-family: 'Fedra';
  src: url('./fonts/FedraSansAltStd_complete_OTF/FedraSansAltStd-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
  font-size: 16px;
}

@font-face {
  font-family: 'Fedra';
  src: url('./fonts/FedraSansAltStd_complete_OTF/FedraSansAltStd-Medium.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
}

@font-face {
  font-family: 'Fedra-Cd';
  src: url('./fonts/FedraSansCondAltStdMedium_OTF/FeSaCdAltStd-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
}

html {
  height: 100%;
  background-color: #F5F2E4;
  font-size: 16px;
}

body {
  height: 100%;
  margin: 0;
  font-family: "Fedra", "SansSerif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: "lnum";
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%
}

input {
  font-family: "Fedra", "SansSerif" !important;
}

label {
  font-family: "Fedra", "SansSerif" !important;
}

a {
  color: #67bad3 !important;
}